(function () {
  'use strict';

  class Gallery {
    constructor($mdDialog, $document) {
      let vm = this;
      vm.$mdDialog = $mdDialog;
      vm.body = $document[0].body;
    }

    show(ev, callback) {
      const vm = this;
      vm.$mdDialog.show({
        controller: 'GalleryCtrl',
        controllerAs: 'gallery',
        templateUrl: 'components/gallery/gallery.tpl.html',
        parent: angular.element(vm.body),
        targetEvent: ev,
        clickOutsideToClose: true
      })
        .then(callback);
    }
  }

  /**
   * @ngdoc service
   * @name components.service:Gallery
   *
   * @description
   *
   */
  angular
    .module('components')
    .service('Gallery', Gallery);
}());
